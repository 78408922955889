import React from "react"
import { Helmet } from "react-helmet"


const NotFoundPage = () => (
  <div>
  <Helmet
    title="Alarm project website"
  >
      <html lang="en" />
  </Helmet>
  
  <p>404</p>
  </div>
)

export default NotFoundPage
